<template>
  <div class="page-industry-templates">
    <h2 class="p-title">模板库</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card">
      <yos-button class="yos-button-primary" to="/fun/template_libraries/new">创建模板库</yos-button>
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>模板：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索模板编号/标题"
        />
        <yos-button class="yos-button-primary" type="submit">搜索</yos-button>
        <yos-button @click="onReset">重置</yos-button>
      </form>
      <table cellspacing="0" class="m-table">
        <thead>
          <tr>
            <th v-if="appid"><yos-checkbox v-model="allCheckeds" @change="onAllChange" /></th>
            <th>编号</th>
            <th>标题</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td v-if="appid"><yos-checkbox v-model="checkeds[item.id]" @change="onAppidChange" /></td>
            <td>{{item.id}}</td>
            <td>{{item.title}}</td>
            <td>{{item.remark}}</td>
            <td>
              <router-link class="option" :to="`/fun/template_libraries/${item.id}`">编辑</router-link>
              <span class="option" @click="onDelete(item.id)">删除</span>
            </td>
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
      <bottom-options v-if="appid" @save="onSave" saveText="添加" />
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import YosPager from '../../components/other/yos-pager'
import BottomOptions from '@/components/common/bottom-options'
import { reactive, toRefs } from 'vue'
import { useServer, usePage, useTabNav, useDialog, useToast } from '../../hook'
import { useRoute, useRouter } from 'vue-router'
import {
  getTemplateLibraries,
  deleteTemplateLibrary,
  addTemplates
} from '../../data'
import { getPageNavs } from '../../util'

export default {
  name: 'Message',
  components: {
    YosTabNav,
    YosPager,
    BottomOptions
  },
  setup() {
    const toast = useToast()
    const dialog = useDialog()
    const route = useRoute()
    const router = useRouter()
    const nav = getPageNavs('fun')
    const state = reactive({
      appid: route.query.appid,
      keyword: route.query.keyword || '',
      authorizer: route.query.authorizer || '',
      platform: parseInt(route.query.platform) || 1,
      totalPage: -1,
      dataList: [],
      allCheckeds: false,
      checkeds: {}
    })

    const { page, baseUrl } = usePage('/fun/template_libraries', { keyword: state.keyword })
    const librariesParams = { keyword: state.keyword, authorizer: state.authorizer, platform: state.platform, page }

    const onAppidChange = () => {
      state.allCheckeds = Object.keys(state.checkeds).filter(key => state.checkeds[key]).length === Object.keys(state.checkeds).length
    }

    const onAllChange = () => {
      const checkeds = {}
      Object.keys(state.checkeds).forEach(key => {
        checkeds[key] = state.allCheckeds
      })
      state.checkeds = checkeds
    }

    const setTemplateLibrariesParams = useServer(getTemplateLibraries, ({ dataList, totalPage }) => {
      state.dataList = dataList
      dataList.forEach(item => {
        state.checkeds[item.id] = false
      })
      state.totalPage = totalPage
    })

    const setDeleteTemplateLibraryParams = useServer(deleteTemplateLibrary, ({ msg }) => {
      toast(msg)
      setTemplateLibrariesParams(params)
    })

    const setAddTemplatesParams = useServer(addTemplates, ({ msg }) => {
      toast(msg)
      router.back()
    })

    const onTabChange = (idx) => {
      const params = { keyword: '', authorizer: '', platform: idx, page: 1 }
      state.platform = idx
      state.keyword = ''
      state.authorizer = ''
      router.replace(serialize(baseUrl, params))
      setTemplateLibrariesParams(params)
    }

    const onPageChange = (page) => {
      setTemplateLibrariesParams({ ...librariesParams, page })
    }

    const onDelete = (id) => {
      dialog({
        title: '删除模板库模板',
        content: '你确定要删除该模板库模板吗？',
        okText: '删除',
        onOk: () => {
          setDeleteTemplateLibraryParams({id})
        }
      })
    }

    const onSubmit = () => {
      const params = { ...librariesParams, page: 1 }
      router.replace(serialize(baseUrl, params))
      setTemplateLibrariesParams(params)
      // sendTemplateMessage('824993', 'TM00004')
    }

    const onReset = () => {
      state.keyword = ''
      state.authorizer = ''
      onSubmit()
    }

    const onSave = () => {
      const libraryIds = Object.keys(state.checkeds).filter(key => state.checkeds[key] === true).map(key => key).join(',')
      setAddTemplatesParams({authorizerAppids: state.appid, libraryIds})
    }

    setTemplateLibrariesParams({ ...librariesParams })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      onTabChange,
      onPageChange,
      onAppidChange,
      onAllChange,
      onDelete,
      onSubmit,
      onReset,
      onSave
    }
  }
}
</script>

<style lang="postcss">
.page-industry-templates {

}
</style>
